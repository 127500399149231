import React, { useState, useEffect } from "react"
import Layout from "../../../../components/layout"
import {
  Table,
  Input,
  Popconfirm,
  Form,
  Typography,
  Space,
  Button,
  Modal,
  Select,
} from "antd"
import { CpiService } from "../../../../services/cpi.service"
import { Link } from "gatsby"
import firebase from "gatsby-plugin-firebase"
import PageHeading from "../../../../components/pageHeading"
import { FileService } from "../../../../services/file.service"
const { Option } = Select

const folderOptions = [
  {
    name: "publicPhoto",
    displayName: "Gallery Images",
    msg:
      "Please upload photos of your instrument. These will be visible in an image gallery on your instrument’s profile.",
  },
  {
    name: "publicCertificate",
    displayName: "Certificate",
    msg: "Please upload scans of certificates for this instrument.",
  },
  {
    name: "publicPress",
    displayName: "Press",
    msg: "Please upload scans of articles or external website links.",
  },
  {
    name: "publicReference",
    displayName: "Reference",
    msg: "",
  },
  {
    name: "publicTechnicalEndoscopePhotos",
    displayName: "Technical Endoscope Photos",
    msg: "",
  },
  {
    name: "publicTechnicalX-Rays",
    displayName: "Technical X-Rays",
    msg: "",
  },
  {
    name: "publicTechnicalHi-ResolutionPhotos",
    displayName: "Technical Hi-Resolution Photos",
    msg: "",
  },
  {
    name: "publicTechnicalMRIs",
    displayName: "Technical MRIs, CT Scan, and Article",
    msg: "",
  },
  {
    name: "publicTechnicalUVPhotos",
    displayName: "Technical UV Photos",
    msg: "",
  },
]

export default function Media({ id }) {
  function AddMediaFolder({ visible, onCreate, onCancel, data }) {
    let unselected = folderOptions
      .filter(val => !mediaData.find(item => item.name === val.name))
      .map(option => (
        <Option value={option.name} key={option.name}>
          {option.displayName}
        </Option>
      ))

    const [form] = Form.useForm()
    return (
      <Modal
        visible={visible}
        title="Media Folder"
        okText="Submit"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              form.resetFields()
              if (data.key) {
                onCreate(values, data.key)
              } else {
                onCreate(values)
              }
            })
            .catch(info => {
              //console.log("Validate Failed:", info)
            })
        }}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            name="name"
            label="Folder"
            initialValue={
              data ? (data.customizeFolder ? "other" : data.name) : null
            }
            rules={[
              {
                required: true,
                message: "Please select folder",
              },
            ]}
          >
            <Select placeholder="Select a folder">
              {unselected}
              <Option value="other">Other</Option>
            </Select>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.name !== currentValues.name
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("name") === "other" ? (
                <Form.Item
                  name="customizeFolder"
                  initialValue={data ? data.customizeFolder : null}
                  label="Folder Name"
                  rules={[
                    {
                      required: true,
                      message: "Folder name is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : (
                <Typography.Text>
                  {folderOptions.find(x => x.name === getFieldValue("name"))
                    ? folderOptions.find(x => x.name === getFieldValue("name"))
                        .msg
                    : null}
                </Typography.Text>
              )
            }
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  const [form] = Form.useForm()
  const [mediaData, setMediaData] = useState([])
  const [modalShow, setAddModal] = useState(false)
  const [modalData, setModalData] = useState({})

  useEffect(() => {
    return firebase
      .firestore()
      .collection("cpi/" + id + "/media/")
      .onSnapshot(snapshot => {
        const data = []
        snapshot.forEach(doc => data.push({ ...doc.data(), key: doc.id }))
        setMediaData(data)
      })
  }, [id])

  const handleDelete = key => {
    FileService.deleteFolder(
      "cpi/" + id + "/media/" + key,
      "cpi/" + id + "/media/" + key
    )
  }

  const onCreate = (values, key) => {
    values.createdAt = firebase.firestore.Timestamp.now()

    if (values.name === "other") {
      values.displayName = values.customizeFolder
    } else {
      values.customizeFolder = null
      values.displayName = folderOptions.find(
        f => f.name === values.name
      ).displayName
    }

    Object.keys(values).forEach(key => {
      if (values[key] === null || values[key] === undefined) delete values[key]
    })

    if (key) {
      CpiService.updateMedia(id, key, values)
    } else {
      CpiService.createMedia(id, values)
    }
    setAddModal(false)
    setModalData({})
  }

  const columns = [
    {
      title: "Folder Name",
      dataIndex: "displayName",
      // editable: true,
    },

    {
      title: "Operation",
      dataIndex: "operation",
      width: "30%",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Typography.Link
              onClick={() => {
                setAddModal(true)
                setModalData(record)
              }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record.key)}
            >
              <button>Delete</button>
            </Popconfirm>
            <Link
              to={"/filebrowser/"}
              state={{ path: "cpi/" + id + "/media/" + record.key + "/file/" }}
            >
              Upload Files
            </Link>
          </Space>
        )
      },
    },
  ]

  return (
    <Layout>
      <PageHeading title="Media Folder" />
      <div className="flex flex-row justify-between p-3 pt-8">
        {/* <div className="text-lg">Media Table</div> */}
        <button
          className="mr-2 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow transition transform  backface-visibility-hidden active:bg-opacity-40 hover:scale-105 hover:bg-opacity-30 focus:outline-none bg-opacity-20 "
          onClick={() => {
            setAddModal(true)
          }}
        >
          Add
        </button>
      </div>
      <Form form={form} component={false}>
        <Table
          className="w-screen"
          bordered
          dataSource={mediaData}
          columns={columns}
        />
      </Form>
      <AddMediaFolder
        visible={modalShow}
        onCancel={() => {
          setAddModal(false)
          setModalData({})
        }}
        onCreate={onCreate}
        data={modalData}
      />
    </Layout>
  )
}
