import React from "react"
import { navigate } from "gatsby"

// import { AuthContext } from "../context/auth"
import { authService } from "../services/auth.service"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  // const { user } = useContext(AuthContext)
  let isLoggedIn = authService.isLoggedIn()

  // if (user) {
  if (!isLoggedIn && location.pathname !== `/login`) {
    navigate("/login")
    return null
  }
  // }
  return <Component {...rest} />
}
export default PrivateRoute
