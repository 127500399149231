import React from "react"
import { Router } from "@reach/router"
import Account from "./app/account"
import PrivateRoute from "../utils/privateRoute"
import Instrument from "./app/instrument"
import EditIns from "./app/instrument/edit/[id]"
import NewIns from "./app/instrument/new"
import Media from "./app/instrument/media/[id]"
import Provenance from "./app/instrument/provenance/[id]"
import CPAform from "./app/cpaform"
import RedirectSubmit from "./app/instrument/redirectsubmit"
import ConditionReport from "./app/instrument/conditionReport/[id]"

const App = () => (
  <Router>
    <PrivateRoute path="/app/account" component={Account} />
    <PrivateRoute path="/app/instrument" component={Instrument} />
    <PrivateRoute
      path="/app/instrument/redirectsubmit"
      component={RedirectSubmit}
    />
    <PrivateRoute path="/app/instrument/new/" component={NewIns} />
    <PrivateRoute path="/app/instrument/edit/:id" component={EditIns} />
    <PrivateRoute path="/app/instrument/media/:id" component={Media} />
    <PrivateRoute path="/app/cpa" component={CPAform} />
    <PrivateRoute
      path="/app/instrument/provenance/:id"
      component={Provenance}
    />
    <PrivateRoute
      path="/app/instrument/conditionReport/:id"
      component={ConditionReport}
    />
  </Router>
)
export default App
